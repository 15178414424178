let mainPath = "/superadmin";
let viewFolder = "SuperAdmin";

module.exports = [
    {
        path: mainPath,
        name: 'superadmin',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/documenttypes',
        name: 'Document Types',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/DocumentTypes.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/paymenttypes',
        name: 'Payment Types',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentTypes.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/transactiontypes',
        name: 'Transaction Types',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/TransactionTypes.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/permissions',
        name: 'Permissions',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Permissions.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/creditnotetypes',
        name: 'Credit Note Types',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/CreditNoteTypes.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/paymentmethods',
        name: 'Payment Methods',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentMethods.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/shippingzones',
        name: 'Shipping Zones',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/ShippingZones.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/shippingmethods',
        name: 'Shipping Methods',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/ShippingMethods.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/globals',
        name: 'Globals',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Globals.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/dbutility',
        name: 'DB Utility',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Sync.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/shopmonkey',
        name: 'Shopmonkey',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Shopmonkey.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/adminutilities',
        name: 'Admin Utilities',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/AdminUtilities.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]