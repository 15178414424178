import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad';

axios.defaults.withCredentials = true;

axios.interceptors.response.use(undefined, async function axiosRetryInterceptor(err){
  if(err && err.response && err.response.status == 401){
      console.log(err)
      localStorage.removeItem('id')
      await router.push({path: '/login'})
      router.go()
  }
})

Vue.config.productionTip = false
Vue.use(VueSignaturePad)


new Vue({
  router,
  store,
  vuetify,
  VueSignaturePad,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App)
}).$mount('#app')
