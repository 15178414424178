export default {
    parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
    },
    getStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    formatDate(d, type="short"){
        if(!d) return "eNone";
        if(type=="short")
            return (new Date(d)).toLocaleDateString('en-GB')
        if(type=="long")
            return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    formatTime(t){
        if(!t) return "None";
        return (new Date(t).toLocaleTimeString('en-US'))
    },
    formatCurrency(value){
        if(value===null || value===undefined) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
    },
    parseDate(date, type){
        if(!date){
            return ""
        }
        switch (type) {
            default:
                return new Date(date).toISOString().substring(0, 10);
            case 1:
                return new Date(date).toISOString().replace(/-/g, '/').replace(/T.+/, '')
            case 2:
                return new Date(new Date(date).toISOString().replace(/-/g, '/').replace(/T.+/, '')).toDateString()
            case 3:
                return new Date(new Date(date)).toLocaleString();
        }
    }
}