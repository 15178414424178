<template>
  <div style="width: 100%;" class="d-flex flex-coumn justify-center">
    <v-progress-circular
        indeterminate
        color="green"
        v-if="initialLoader"
        style="margin-left: 10px;"
    ></v-progress-circular>
    <v-row v-else>
      <v-col>
        <h1>Search for a product.</h1>
        <span class="d-flex flex-column">
          <v-card outlined>
            <v-card-text>
              <span class="d-flex flex-row align-center">
              </span>
              <span class="d-flex flex-row align-center justify-center">
                <v-text-field style="width: 100%;" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" outlined></v-text-field>
              </span>
              <p v-if="noSearchResults">No search results were found.</p>
              <span v-if="productSearchResults&&productSearchResults.length>0">
                <span class="d-flex flex-row justify-center align-center">
                  <b>Found {{productSearchResults.length}} Search Results</b>
                  <v-btn color="info" style="margin-left: 10px;" @click="clearProductSearchResults">Clear</v-btn>
                </span>
                <div v-for="item in productSearchResults" :key="item.id">
                  <span class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                    <div class="d-flex flex-column justify-space-between">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span><b>SP: ${{item.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{item.salePrice}}</span></span>
                        <span><b>QTY: {{item.ProductLocationJoins.find(x => x.userBranch===true)?item.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{item.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span>
                        <!-- <p>{{item}}</p> -->
                        <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                      </span>
                    </div>
                    <v-btn fab color="success" @click="goToProduct(item)" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </span>
                </div>
              </span>
            </v-card-text>
          </v-card>
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios';

export default {
  name: 'Home',
  data () {
    return {
      initialLoader: true,
      deliverySchedule: {
        tableHeaders:[
          {text: 'Delivery ID', align: 'start', value: 'id'},
          { text: 'Customer', value: 'Order.Customer.fullName' },
          { text: 'Delivery Date', value: 'dueDate' },
          { text: '# of Items', value: 'numItems' },
          { text: 'Order #', value: 'orderId' },
          { text: 'Status', value: 'status' },
        ],
        data: null,
      },
      orders: {
        tableHeaders:[
          {text: 'Order ID', align: 'start', value: 'id'},
          { text: 'Date Created', value: 'createdAt' },
          { text: 'CSR', value: 'CSR.fullName' },
          { text: 'Customer', value: 'Customer.fullName' },
          { text: 'Status', value: 'status' },
        ],
        data: null,
      },
      productSearchTerm: '',
      loadingSearchProduct: false,
      productSearchResults: [],
      noSearchResults: false
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed', 'getGlobalValue'])
  },
  async mounted(){
    try {
      if(this.$route.query?.q){
        this.productSearchTerm = this.$route.query.q
        await this.searchProduct()
      }

    } catch (error) {
      console.log(error)
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    deliveryRowClick(row){
      this.$router.push({path: `/deliveries/view/${row.id}`})
    },
    orderRowClick(row){
      this.$router.push({path: `/orders/view/${row.id}`})
    },
    parseDeliveryStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
      else if(id===2) return "Delivered"
      else if(id===3) return "Completed"
    },
    parseOrderStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
      else if(id===2) return "Admin Sealed"
      else if(id===3) return "Delivery Scheduled"
      else if(id===4) return "Pending Reschedule"
      else if(id===5) return "Out For Delivery"
      else if(id===6) return "Delivered"
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    async searchProduct(){
      try {
        if(this.productSearchTerm){
          this.loadingSearchProduct = true;
          if(this.$route.path==="/" && this.$route.query?.q!==this.productSearchTerm) await this.$router.replace({query: {q: this.productSearchTerm}})
          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?val=${encodeURIComponent(this.productSearchTerm)}`);
          if(res.data.error) throw res.data.error
          this.productSearchResults = res.data.data
          if(this.productSearchResults.length == 0) this.noSearchResults = true
          else this.noSearchResults = false
        }
      } catch (error) {
        console.error(error)
        this.snack(error, "error")
      }finally {
        this.loadingSearchProduct = false;
      }
    },
    clearProductSearchResults(){
      this.productSearchResults = []
      this.productSearchTerm = ""
    },
    watchInput(){
      if(this.productSearchTerm.length == 0) this.noSearchResults = false
    },
    async goToProduct(prod){
      let id = prod.id;
      if(prod.type==="variation") id = prod.parent;
      await this.$router.push({path: `/products/view/${id}`})
    }    
  }
}
</script>
