let mainPath = "/reporting";
let viewFolder = "Reporting";

module.exports = [
    {
        path: mainPath,
        name: 'reports',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath+'/discounts',
        name: 'Discounts',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Discounts.vue'),
    },
    {
        path: mainPath+'/ordersales',
        name: 'Order Sales',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/OrderSales.vue'),
    },
    {
        path: mainPath+'/commissions',
        name: 'Commissions',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Commissions.vue'),
    },
    {
        path: mainPath+'/supplierinventory',
        name: 'Supplier Inventory',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/SupplierInventory.vue'),
    }
]